import React from 'react'
import "../App.css"
import partnersLogo from "../assets/ourpartnersblackandwhite.png"
import woman from "../assets/woman.png"
import homeguy from "../assets/homeguy.png"
import manandwoman from "../assets/manandwoman.png"
import Subscribe from "../components/Subscribe";
import g from "../assets/g.png"
import Card1 from "../components/Card1";
import Button from "../components/Button";
import effect_circle_dot from "../assets/effect_circle_dot.png"
import secure from "../assets/secure.svg"
import circle_dot from "../assets/circle_dot.svg"
import circle_dot3 from "../assets/circle_dot3.svg"


const HomeScreen = () => {

    return (
        <div>
            <img src={effect_circle_dot} className="effect_circle_dot"  alt="#"/>
            <img src={circle_dot} className="circle_dot" alt="#" />
            <img src={circle_dot3} className="circle_dot3" alt="#" />
            <div className="home-wrapper" style={{backgroundImage:`url(${homeguy})`}}>
                <div className="home-wrapper-overlay">
                <div className="home-wrapper-overlay-inner">
                        <h3>Africa’s <span style={{color:'#F7EF8A'}}>Gold</span> Standard for Payment & Processing</h3>
                        <p>CoralPay is the first choice in payments and payment-centric solutions in Nigeria and the West Africa sub-region.</p>
                        <div className="home-btn">
                            <a href="mailto:rbe@coralpay.com"><Button message="Contact Sales" bgColor="#FFFFFF" bgText="#7E315F" /></a>
                           <a href="/developer"> <Button  message="Check Our API Docs" bgColor="#F7EF8A" bgText="#3A3A3A"/></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="man">
                <div className="man-flex">
                    <div className="man-flex-inner-1">
                        <h3>Grow Your
                            Business With
                            Our Products &
                            Services</h3>
                        <p>Start Accepting Payment With Our Payment Gateway</p>
                    </div>
                    <div className="woman">
                        <img src={woman} alt="woman holding phone" />
                    </div>
                </div>
                <div>
                <img src={manandwoman} width="1000" alt="man ans woman together"/>
                </div>
                <h4>Let’s Meet Your Business Needs
                    With <span style={{color:'#7E3D64'}}>Our Solutions</span></h4>

                <p>CoralPay offers a wide range of services
                    to help you grow your business.</p>
            </div>

            <div className="section-card">
                <div className="section-card-inner">
                    <Card1 />
                </div>
            </div>
            <div className="reliable">
                <div className="man-flex-reliable">
                    <div className="man-flex-inner">
                        <h3>Reliable & Secure</h3>
                        <div className="ha-desk">
                            <div className="ha">
                                <img src={secure}  className="mobile-for-wwd" alt="#" />
                            </div>
                        </div>
                        <div className="ha-mobile">
                            <h1 className="ha-mobile-h">Your Safety and Security is Our Priority</h1>
                        </div>
                    </div>
                    <div>
                        <img src={g} className="g-img" alt="someimage" />
                    </div>
                </div>
            </div>
            <div className="doc-container">
                <div className="doc">
                    <h3>Explore Our API Developers Documentation</h3>
                    <p className="doc-p">CoralPay provides modern financial infrastructure for companies and developers in Africa to access financial data,
                        issue virtual bank accounts, payments, and collect payments directly from bank accounts.</p>
                    <a href="/developer"><Button message="Read Our API Docs" bgColor="#FFFFFF" bgText="#244E76" /></a>
                </div>
            </div>
            <div className="ourPartners">
                <h3>Our Partners</h3>
                <img className="partnersLogo" src={partnersLogo}  alt="d"/>
            </div>
            <Subscribe />
        </div>
    )
}

export default HomeScreen