import React from "react"


const CoralpayPaymentGatewayDocumentation = () => {
    return(
        <div>
            <div className="developer-wrapper">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>CORALPAY GATEWAY</h3>
                        <p className="mt-2 small">This document is a technical and integration document for the CoralPay Verge payment gateway. It
                            provides guidance and template material which is intended to assist the relevant stakeholders to easily
                            integrate to the payment interface for collections and processing of transactions seamlessly.</p>
                        <br /><br />
                        {/*<div style={{display:'flex'}} >*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="doc-div-parent-doc">

                <div className="docNav">
                    <h3>Documentation</h3>
                    <ul>
                        <a href={'/developer/docs/cgateussd'}> <li>C’Gate USSD</li></a>
                        <a href={'/developer/docs/pay-with-bank-transfer'}> <li>Pay With Bank Transfer</li></a>
                        <a href={'/developer/docs/payment-gateway'}> <li>CoralPay Payment Gateway</li></a>
                        <a href={'/developer/docs/vas'}> <li>CoralPay VAS</li></a>
                        <a href={'/developer/docs/card-api'}> <li>CoralPay Card APIs</li></a>
                    </ul>
                </div>


                <div className="doc-div-doc">
                    <h3>Introduction</h3>

                    <p>This document is a technical and integration document for the CoralPay Verge payment gateway. It
                        provides guidance and template material which is intended to assist the relevant stakeholders to easily
                        integrate to the payment interface for collections and processing of transactions seamlessly.</p>

                    <p>The gateway is a combined channel of payment which opens all available channels to the customers to
                        choose for their payments and a single integration to Verge suffices to enjoy all channels</p>


                    <h3>Available Channels</h3>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>C’Gate USSD</li>
                        <li>Card Payment</li>
                        <li>NQR</li>
                        <li>Bank Transfers</li>
                    </ul>

                    <h3>Channels Description</h3>
                    <span><b>Cgate Ussd</b></span>
                    <p>This is a payment channel that rides on the banks’ Ussd platform to make payment on App/Web/POS,
                        where a dynamic USSD string is generated based on the selected bank by the customer. The customer can
                        tap the USSD string to pay or copy the USSD string to the phone dial to approve the transaction. On
                        approving the transaction via the Banks USSD, the gateway automatically displays the status of the
                        transaction and route back to the merchant’s website.</p>
                    <span><b>Card Payment</b></span>
                    <p>The card payment channel is the one where the customer enters his Debit/Credit card details to approve
                        a transaction, and upon successful transaction, the gateway routes the customer back to the merchant’s
                        website</p>
                    <span><b>NQR</b></span>
                    <p>The NQR channel is the one where a QR is generated by the gateway to be scanned by the customer using
                        their respective bank mobile app. The gateway immediately responds to the status of the transaction and
                        route back to the merchant’s website. Note: There is a module for NQR on the banks mobile app to make
                        payment.</p>
                    <span><b>Bank Transfer</b></span>
                    <p>This payment channel allows the customer to transfer the specified amount to a dynamically generated
                        account number, and the gateway displaces the status of the transaction immediately the fund is received,
                        hence the gateway routes back to the merchant website.</p>



                    <h3>API Specification</h3>
                    <p>Merchants are required to be registered on CoralPay’s platform and the credentials to call the API
                                                                            operations will be shared.</p>
                    <p>CoralPay Verge uses the JWT authentication method to authenticate users calling the API, and a Key to
                        generate the Signature value for every service calls.</p>

                    <h3>Authentication</h3>

                    <p>Merchants are required to call the Authentication operation using the credentials provided by CoralPay
                        during registration. The Response from this operation is to be used for authentication for other operations
                        using bearer token authentication type.</p>

                    <br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Url</td>
                            <td>https://testdev.coralpay.com/VergeTest/api/v1/authentication</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                        <td>Content Type</td>
                        <td>Json</td>
                    </tr><tr>
                        <td>Authentication</td>
                        <td>Basic Username : Password</td>
                    </tr><tr>
                        <td>Request Payload</td>
                        <td> {`{`} <br />
                            {`"Username": "SkaiKru",`} <br />
                            {`"Password": "#1ooGr@0ndar$"`}<br />
                            {`}`}</td>
                    </tr>
                        <tr>
                            <td>Response Payload</td>
                            <td>{`{`} <br />
                                {`"Token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",`} <br />
                                {`"Key": "2092ba6b-742b-4df1-af34-7def3bc004c1",`}<br />
                                {`"Status": "Success" `}<br />
                                {`}`}</td>
                        </tr>
                    </table>
                    <br />
                    <span><b>Properties Description</b></span>
                    <table style={{width:'910px'}}>
                        <tr>
                            <th>Properties</th>
                            <th>Description</th>
                            <th>Optional/Mandatory</th>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>This is a unique username assigned to the merchant.
                                Datatype: String.</td>
                            <td>YES</td>
                        </tr><tr>
                        <td>Password</td>
                        <td>The is the Corresponding password to the Username.
                            Datatype: String</td>
                        <td>YES</td>
                    </tr><tr>
                        <td>Token</td>
                        <td>The is the generated token used for the bearer token
                            authentication type. Datatype: String</td>
                        <td>YES</td>
                    </tr>
                        <tr>
                        <td>Key</td>
                        <td>This is used to generate the signature value on
                            operation call. Datatype: String</td>
                        <td>YES</td>
                    </tr><tr>
                        <td>Status</td>
                        <td>This is the status of the call which is either Success or
                            Failed. Datatype: String.</td>
                        <td>YES</td>
                    </tr>
                    </table>
                    <br />

                   <span>InvokePayment</span>
                    <p>This is the operation to initiate payment on the CoralPay verge gateway</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Url</td>
                            <td>https://testdev.coralpay.com/VergeTest/api/v1/Invokepayment</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                            <td>Content Type</td>
                            <td>Json</td>
                        </tr><tr>
                        <td>Authentication</td>
                        <td>Bearer Token Authentication type. See <a href='#d'> here</a></td>
                    </tr><tr>
                        <td>Request Payload</td>
                        <td> {`{

                            "RequestHeader": {
                            "MerchantId": "10990MTE0992",
                            "TimeStamp": "0123456789",
                            "Signature": "0928uymd0dkq445553222pl9"
                        },
                            "Customer": {
                            "Email": "babavoss@see.com",
                            "Name": "Baba Voss",
                            "Phone": "002200302092"
                        },
                            "Customization": {
                            "LogoUrl": "http://sampleurl.com",
                            "Title": "Watermarks Limited",
                            "Description": "Service Payment"
                        },
                            "MetaData": {
                            "Data1": "sample string 1",
                            "Data2": "sample string 2",
                            "Data3": "sample string 3"
                        },
                            "TraceId": "9900990285",
                            "Amount": 2000.00,
                            "Currency": "NGN",
                            "FeeBearer": "M",
                            "ReturnUrl": "http://samplereturnurl.com/status"
                        }`}</td>
                    </tr>
                        <tr>
                            <td>Response Payload</td>
                            <td>{`{
                                "ResponseHeader": {
                                "ResponseCode": "00",
                                "ResponseMessage": "Successful",
                                "TimeStamp": 0123456789,
                                "Signature": "120dksl3l3lld303e3k3mjmf36k6j3h"
                                },
                                "TransactionId": "029918891988381",
                                "TraceId": "9900990285",
                                "PayPageLink": "https://coralpay.ng/payment/00998833",
                                "MetaData": {
                                "Data1": "sample string 1",
                                "Data2": "sample string 2",
                                "Data3": "sample string 3"}`}
                            </td>
                        </tr>
                    </table>
                    <br />
                    <span><b>Properties Description</b></span>
                    <table style={{width:'910px'}}>
                        <tr>
                            <th>Properties</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Optional/Mandatory</th>
                        </tr>
                        <tr><th>RequestHeader</th></tr>
                        <tr>
                            <td>MerchantId</td>
                            <td>This is the Merchant unique identifier</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>TimeStamp</td>
                            <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                            <td>Long</td>
                            <td>YES</td>
                    </tr>
                        <tr>
                            <td>Signature</td>
                            <td>This value is generated to maintain the integrity of the
                                payload. Note that a new Timestamp must be generated
                                to calculate the signature every API call.
                                Sha256(MerchantId +TraceId+ TimeStamp + Key).</td>
                            <td>String</td>
                            <td>YES</td>
                    </tr>
                        <tr><th>Customer</th></tr>
                        <tr>
                            <td>Email</td>
                            <td>This is a customer property, which is the customer
                                email</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>This is a customer property, which is the customer
                                Phone No.</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>This is a customer property, which is the customer
                                Name.</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>

                        <tr><th>Customization</th></tr>
                        <tr>
                            <td>MetaData</td>
                            <td>This object can be used for additional information.</td>
                            <td>Object</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>TraceId</td>
                            <td>This is the unique identifier from the merchant</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>This is the amount of the transaction.</td>
                            <td>Decimal</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>FeeBearer</td>
                            <td>This specifies who bears the fee/charges of the
                                transaction. The value for the property is either M or C
                                M=Merchant, C=Customer</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>This is the currency of the transaction. e.g., NGN</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>ReturnUrl</td>
                            <td>This is the URL to return to when payment is completed.
                                We’ll include Id and Status as query strings in the URL
                                while routing to it. Also note that a real-time notification
                                will be pushed via the call-back.</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>

                    </table>
                    <br />
                    <span><b>Transaction Query</b></span>
                    <p>This operation can be called to get transaction details not more than 3 months.</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Url</td>
                            <td>https://testdev.coralpay.com/VergeTest/api/v1/transactionquery</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                            <td>Content Type</td>
                            <td>application/json</td>
                        </tr><tr>
                        <td>Authentication</td>
                        <td>Bearer Token Authentication type. See here</td>
                    </tr><tr>
                        <td>Request Payload</td>
                        <td> {`{
                                "RequestHeader": {
                                "MerchantId": "10990MTE0992",
                                "TimeStamp": "0123456789",
                                "Signature": "0928uymd0dkq445553222pl9"
                                },
                                "TraceId": "9099388490"
                                }`}</td>
                    </tr>
                        <tr>
                            <td>Response Payload</td>
                            <td>{`{                              
                                    "MerchantId": "10990MTE0992",
                                    "TraceId": "9099388490",
                                    "TransactionId": "099387777727777724",
                                    "PaymentDate": "2021-11-21T15:39:33.8838432+01:00",
                                    "Channel": "USSD",
                                    "Amount": 6000.0,
                                    "Fee": 60.0,
                                    "FeeBearer": "M",
                                    "Title": "Watermarks Limited ",
                                    "Description": "Service payment",
                                    "TimeStamp": 0123456689,
                                    "Signature": "3436557434lkejfd4oiw3i3pd",
                                    "ResponseCode": "00",
                                    "ResponseMessage": "Successful"
                                    }`}</td>
                        </tr>
                    </table>
                    <p>Properties description</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <th>Properties</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Mandatory</th>
                        </tr>
                        <tr><th>RequestHeader</th></tr>
                        <tr>
                            <td>MerchantId</td>
                            <td>This is the Merchant unique identifier</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>TimeStamp</td>
                            <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                            <td>Long</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>Signature</td>
                            <td>This value is generated to maintain the integrity of the
                                payload. Note that a new Timestamp must be generated
                                to calculate the signature every API call.
                                Sha256(MerchantId +TraceId+ TimeStamp + Key).</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>Title</td>
                            <td>Transaction title as in the payment request</td>
                            <td>String</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>Transaction description as in the payment request</td>
                            <td>String</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TraceId</td>
                            <td>This is the unique identifier from the merchant</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>This is the amount of the transaction.</td>
                            <td>Decimal</td>
                            <td>YES</td>
                        </tr>

                        <tr>
                            <td>Fee</td>
                            <td>This is the transaction fee</td>
                            <td>Decimal</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>FeeBearer</td>
                            <td>This specifies who bears the fee/charges of the
                                transaction. The value for the property is either M or C
                                M=Merchant, C=Customer</td>
                            <td>String</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>This is the currency of the transaction. e.g., NGN</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>ResponseCode</td>
                            <td>This is the status code for the transaction.</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>ResponseMessage</td>
                            <td>The is the status code message for the transaction.</td>
                            <td>String</td>
                            <td>YES</td>
                        </tr>

                    </table>
                    <br />
                    <span><b>Call-Back</b></span>
                    <p>This operation is to be created by the merchant to receive real time payment notification; we expect to
                        get Http response 200 upon posting the notification. Verge will repush the notification 6 times within an
                        hour until http response 200 is received.</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Url</td>
                            <td>https://testdev.coralpay.com/VergeTest/api/v1/transactionquery</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                            <td>Content Type</td>
                            <td>application/json</td>
                        </tr><tr>
                        <td>Authentication</td>
                        <td>Basic Auth: using username and password provided by CoralPay</td>
                    </tr><tr>
                        <td>Request Payload</td>
                        <td> {`{
                                "RequestHeader": {
                                "MerchantId": "10990MTE0992",
                                "TimeStamp": "0123456789",
                                "Signature": "0928uymd0dkq445553222pl9"
                                },
                                "TraceId": "9099388490"
                                }`}</td>
                    </tr>
                        <tr>
                            <td>Response Payload</td>
                            <td>{`{                              
                                    "MerchantId": "10990MTE0992",
                                    "TraceId": "9099388490",
                                    "TransactionId": "099387777727777724",
                                    "PaymentDate": "2021-11-21T15:39:33.8838432+01:00",
                                    "Channel": "USSD",
                                    "Amount": 6000.0,
                                    "Fee": 60.0,
                                    "FeeBearer": "M",
                                    "Title": "Watermarks Limited ",
                                    "Description": "Service payment",
                                    "TimeStamp": 0123456689,
                                    "Signature": "3436557434lkejfd4oiw3i3pd",
                                    "ResponseCode": "00",
                                    "ResponseMessage": "Successful"
                                    }`}</td>
                        </tr>
                    </table>
                    <br />
                    <span><b>Error Codes</b></span>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Codes</td>
                            <td>Description</td>
                        </tr>
                        <tr>
                            <td>00</td>
                            <td>Successful</td>
                        </tr>
                        <tr>
                            <td>96</td>
                            <td>General Error or System Challenge</td>
                        </tr>
                        <tr>
                            <td>06</td>
                            <td>Invalid or Missing Parameter</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CoralpayPaymentGatewayDocumentation